import semverGte from 'semver/functions/gte';
import { FaceValidationVersionSupported, PLATFORM, OS, MinVersionSupportedForWK2, CHALLENGES, VIEW_MODES } from '../data/enums';

export const validateVersionSupported = (version, platformId) => {
  if (!version) return false;
  if (PLATFORM[platformId]) return semverGte(version, FaceValidationVersionSupported[platformId.toUpperCase()]);

  return false;
};

export const isSupportedForWebkit2 = (device) => {
  const {
    nativeApp: { os },
    webviewUserAgent: { major, minor, patch },
  } = device;
  const version = `${major || '999'}.${minor || '999'}.${patch || '999'}`;

  return os === OS.Android && semverGte(version, MinVersionSupportedForWK2.Webview.Android);
};

export const isSocialLogin = (type) => type === CHALLENGES.email_or_nickname_or_phone_or_social_login || type === CHALLENGES.email_or_nickname_or_phone_or_social_login_with_recaptcha;

export const isV2LoginViewMode = viewMode => viewMode === VIEW_MODES.landscape;
