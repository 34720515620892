/* eslint-disable security/detect-non-literal-regexp */
const React = require('react');
/**
 * @description Split the text and return found regex in text and remainder.
 * @param  {string} text
 * @param  {RegExp} regExpToFind
 * @returns {Array<string>}
 */
const splitByRegex = (text, regExpToFind) => {
  const [target] = regExpToFind.exec(text) || [];
  const [initialText, finalText] = text.split(target);

  return [initialText, target, finalText];
};

const textBetweenParentheses = /\(([^)]+)\)/;
const maskedTextsAroundTheCursor = (mask, cursorPos) => new RegExp(
  `(^\\${mask}{1,${cursorPos}})|(\\${mask}+[\\s\\S]?)`, 'g',
);

const allMaskedText = (mask) => new RegExp(`(\\${mask}+)`, 'g');

const withNoWrapWord = (text, target, { isSocialLogin } = {}) => {
  const isPlatformNameIncluded = text.includes(target);
  if (!isPlatformNameIncluded || isSocialLogin) return () => <>{text}</>;

  const [firstPhrase, secondPhrase] = text.split(target);

  return (props) => (
    <>{firstPhrase}<span {...props} className="no-wrap-text">{target}</span>{secondPhrase}</>
  );
};

const sessionId = /^ghy-\d{6}-\w+-__-\d+-__-\d+--[A-Z]+_\d+-[A-Z]+_\d+$/;

const uuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

module.exports = {
  splitByRegex,
  regex: {
    textBetweenParentheses,
    maskedTextsAroundTheCursor,
    allMaskedText,
    sessionId,
    uuid,
    isEmail,
  },
  withNoWrapWord,
};
